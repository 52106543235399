import React from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../assets/css/Header.css';

const Header = () =>{    
    return(
        <>  
            <header className="header">
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="#home">
                            <Image src={require('../assets/images/logo/logo.png')} alt="image" className='img-fluid' width="180px"/>
                        </Navbar.Brand>
                        <Navbar.Toggle id="menu_toggle" aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#couple">Pareja</Nav.Link>
                                <Nav.Link href="#story">Historia</Nav.Link>                                
                                <Nav.Link href="#gallery">Galeria</Nav.Link>
                                <Nav.Link href="#rspv">RSVP</Nav.Link>
                                <Nav.Link href="#address">Direccion</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>              
        </>
    )
}
export default Header;

