import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import '../assets/css/OurLoverStory.css';
import Title from './Section-Title';

const OurLovestory = () => {
    const [wow, setWow] = useState(new WOW.WOW());
    useEffect(() => {
        setWow(wow.init());
    }, []);

    return (
        <>
            <section className="love_story section-padding" id="story">
                <Container>
                    <Title sec_title={'Nuestra Dulce Historia'} />
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="story-timeline">
                                <div className="round-shape"></div>
                                <div className="row order_column">
                                    <div className="col col-lg-6 col-12 wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1.5s">
                                        <div className="story-text right-align-text">
                                            <h3>Primera vez que nos vimos</h3>
                                            <span className="date">Oct 06,2016</span>
                                            <p>Edgar vio a Adriana desde lejos en un gimnasio y se imaginaba escenarios en su cabeza sobre como proponerle matrimonio sin siquiera conocerla. Mientras tanto, Adriana vivia su vida feliz pero no al maximo por que no conocia a Edgar.</p>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder">
                                            <Image src={require('../assets/images/our_story/1.jpg')} alt="image" className="img-fluid img img-responsive wow fadeInLeft" data-wow-delay="0.4s" data-wow-duration="1.5s" />
                                            <div className="story-shape-img">
                                                <Image src={require('../assets/images/our_story/shape.png')} alt="image" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder right-align-text left-site">
                                            <Image src={require('../assets/images/our_story/2.jpg')} alt="image" className="img img-fluid img-responsive wow fadeInRight" data-wow-delay="0.6s" data-wow-duration="1.5s" />
                                            <div className="story-shape-img">
                                                <Image src={require('../assets/images/our_story/shape.png')} alt="image" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 text-holder ">
                                        <span className="heart">
                                            <i className="fi flaticon-calendar wow zoomIn" data-wow-delay="0.5s" data-wow-duration="1s"></i>
                                        </span>
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s">
                                            <h3>Primera Cita</h3>
                                            <span className="date">April 16,2017</span>
                                            <p>Nuestra primera cita tiene dos puntos de vista. Fue en un cafe para comer un postre. La version de Edgar dice que uso todas sus tecnicas de conquista y fueron exitosas. Segun Adriana, Edgar no pudo ni mirarle a los ojos. Dejamos a su juicio cual version creer.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row order_column">
                                    <div className="col col-lg-6 col-12 text-holder right-heart">
                                        <span className="heart">
                                            <i className="fi flaticon-dove"></i>
                                        </span>
                                        <div className="story-text right-align-text wow fadeInRight" data-wow-delay="0.5s" data-wow-duration="1.5s">
                                            <h3>Propuesta de Matrimonio</h3>
                                            <span className="date">Noviembre 13,2023</span>
                                            <p>Edgar: Queria que fuera inesperado y algo que recordaran de por vida. Lo mas facil era un viaje a Europa, pero todos esperaban eso. Asi que decidi grabar un video en cuatro paises, con el ultimo destino siendo un sitio increible en un bosque con playa. Nos perdimos una hora en el bosque, pero sobrevivimos y la propuesta fue exitosa. Adriana: Tuve la parte facil, solo dije si al amor de mi Vida.  <a href="https://www.instagram.com/reel/CzpmFAXxXyqhpOgioDlhwv4agaJ9Dzx5_4eIT00/" target="_blank">Video</a>.</p>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder right-align-text">
                                            <Image src={require('../assets/images/our_story/3.jpg')} alt="image" className="img img-fluid img-responsive wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" />
                                            <div className="story-shape-img">
                                                <Image src={require('../assets/images/our_story/shape.png')} alt="image" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-lg-6 col-12">
                                        <div className="img-holder video-holder left-site">
                                            <Image src={require('../assets/images/our_story/4.jpg')} alt="image" className="img img-fluid img-responsive wow fadeInRight" data-wow-delay="0.5s" data-wow-duration="1.5s" />
                                            <div className="story-shape-img">
                                                <Image src={require('../assets/images/our_story/shape.png')} alt="image" className='img-fluid' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 col-12 text-holder">
                                        <span className="heart">
                                            <i className="fi flaticon-wedding-rings wow zoomIn" data-wow-delay="0.5s" data-wow-duration="0.5s"></i>
                                        </span>
                                        <div className="story-text wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s">
                                            <h3>Nuestra Boda</h3>
                                            <span className="date">June 14,2024</span>
                                            <p>Decidimos aventurarnos a hacer 3 eventos: casarnos civilmente en Canada, realizar la boda eclesiastica en Venezuela y el after party en Estados Unidos. Amigos y familia estuvieron con nosotros en Canada, ahora es el turno de Venezuela!!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                        <span className="heart">
                                            <i className="fi ti-search wow zoomIn" data-wow-delay="0.5s" data-wow-duration="0.5s"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default OurLovestory;
