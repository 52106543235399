import React from 'react';
import Image from 'react-bootstrap/Image';
import CoutDown from '../components/Timer'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/Banner.css';
import '../assets/css/Carousel-custom.css';
const Announce_banner = () => {
    return (
        <>
            <section className="wedding_banner" id='home'>
                <div className="wedding_announcement">
                    <h2 className="loader wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="1.2s">
                        <span>R</span>
                        <span>e</span>
                        <span>s</span>
                        <span>e</span>
                        <span>r</span>
                        <span>v</span>
                        <span>a</span>
                        <span>L</span>
                        <span>a</span>
                        <span>F</span>
                        <span>e</span>
			<span>c</span>
                        <span>h</span>
                        <span>a</span>

                    </h2>
                    <p className="wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="1.2s">Falta Poco para nuestra Boda! <span>"Septiembre 14,2024"</span> </p>
                    <div className="wpo-wedding-date">
                        <div className="clock-grids">
                            <div id="clock" className="d-flex">
                                <CoutDown date={'Septiembre 14 2024 00:00:00'} />  {/* Formate:-> date={'Month Date Year Hour Minute Second'} */}
                            </div>
                        </div>
                    </div>
                </div>
                <Carousel fade indicators={false} interval={3500} >
                    <Carousel.Item>
                        <Image
                            className="img-fluid"
                            alt="First slide"
                            width="100%"
                            src={require('../assets/images/slider/slide-1.jpg')}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className="img-fluid"
                            alt="Second slide"
                            width="100%"
                            src={require('../assets/images/slider/slide-2.jpg')}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className="img-fluid"
                            alt="Third slide"
                            width="100%"
                            src={require('../assets/images/slider/slide-3.jpg')}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <Image
                            className="img-fluid"
                            alt="First slide"
                            width="100%"
                            src={require('../assets/images/slider/slide-4.jpg')}
                        />
                    </Carousel.Item>
                </Carousel>
            </section>

        </>
    )
}
export default Announce_banner;