import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import WOW from "wowjs";
import emailjs from 'emailjs-com';
import '../assets/css/Rsvp.css';
import Title from './Section-Title';

const RSVP = (props) => {
    const [wow, setWow] = useState(new WOW.WOW());
    useEffect(() => {
        setWow(wow.init());
    }, []);

    const [forms, setForms] = useState({
        name: '',
        text: '',
        companion: '',
        attend: '',
        event: '',
        drink: '',
        food: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: 'Este campo es obligatorio',
            alpha_space: 'Solo se permiten letras y espacios',
            phone: 'Debe ser un numero de telefono valido'
        }
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            console.log("Enviando datos del formulario:", forms); // Log form data

            emailjs.send("service_3pekf3c", "template_gcjh7uk", {
                name: forms.name,
                text: forms.text,
                companion: forms.companion,
                attend: forms.attend,
                event: forms.event,
                drink: forms.drink,
                food: forms.food
            }, 'HXmcHVj9YJ6E4meFt')
            .then((result) => {
                console.log("Correo enviado exitosamente:", result.text);
                alert("Formulario de RSVP enviado exitosamente!");
            }, (error) => {
                console.log("Error al enviar el correo:", error.text);
                alert("Hubo un error al enviar el formulario de RSVP. Por favor, intentelo de nuevo mas tarde.");
            });

            setForms({
                name: '',
                text: '',
                companion: '',
                attend: '',
                event: '',
                drink: '',
                food: ''
            });
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    return (
        <>
            <section className="rsvp_section section-padding pb-0" id="rspv">
                <Container>
                    <div className="row justify-content-center">
                        <div className="d-none col-md-3 col-xl-3 d-lg-flex align-items-end">
                            <div className="bg_1">
                                <Image src={require('../assets/images/rsvp/1.png')} alt="image" className="img-fluid wow fadeInLeft" data-wow-delay="0.8s" data-wow-duration="1.5s" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-6">
                            <div className="content_wrapper">
                                <div className="rsvpForm_area">
                                    <Title sec_title={'Asistiras?'} />
                                    <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active">
                                        <div className="form-field">
                                            <input
                                                value={forms.name}
                                                type="text"
                                                name="name"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                className="form-control"
                                                placeholder="Tu Nombre" />
                                            {validator.message('name', forms.name, 'required|alpha_space')}
                                        </div>
                                        <div className="form-field">
                                            <input
                                                value={forms.text}
                                                type="text"
                                                name="text"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                className="form-control"
                                                placeholder="Tu Numero de Telefono" />
                                            {validator.message('text', forms.text, 'required|phone')}
                                        </div>
                                        <div className="form-field radio-buttons">
                                            <p className="mb-0">
                                                <input type="radio" id="attend" name="attend" value="Si, asistire" className="d-none" onChange={(e) => changeHandler(e)} />
                                                <label htmlFor="attend">Si, asistire</label>
                                            </p>
                                            <p className="mb-0">
                                                <input type="radio" id="not" name="attend" value="Lo siento, no puedo asistir" className="d-none" onChange={(e) => changeHandler(e)} />
                                                <label htmlFor="not">Lo siento, no puedo asistir</label>
                                            </p>
                                        </div>
                                        <div className="form-field">
                                            <select
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                value={forms.event}
                                                type="text"
                                                className="form-control"
                                                name="event">
                                                <option value="">A cual evento asistiras?</option>
                                                <option value="Iglesia">Iglesia</option>
                                                <option value="Fiesta">Fiesta</option>
                                                <option value="Ambos">Ambos</option>
                                                <option value="Ninguno">Ninguno</option>
                                            </select>
                                            {validator.message('event', forms.event, 'required')}
                                        </div>
                                        <div className="form-field">
                                            <select
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                value={forms.food}
                                                type="text"
                                                className="form-control"
                                                name="food">
                                                <option value="">Selecciona la comida de tu preferencia</option>
                                                <option value="Pollo y arroz">Pollo y arroz</option>
                                                <option value="Pollo y pure">Pollo y pure</option>
                                                <option value="Asado y arroz">Asado y arroz</option>
                                                <option value="Asado y pure">Asado y pure</option>
                                            </select>
                                            {validator.message('food', forms.food, 'required')}
                                        </div>
                                        <div className="form-field">
                                            <input
                                                value={forms.companion}
                                                type="text"
                                                name="companion"
                                                onBlur={(e) => changeHandler(e)}
                                                onChange={(e) => changeHandler(e)}
                                                className="form-control"
                                                placeholder="Quien te acompana?" />
                                            {validator.message('companion', forms.companion, 'required')}
                                        </div>
                                        <div className="submit-area">
                                            <button type="submit" className="theme-btn">Enviar Ahora</button>
                                        </div>
                                    </form>
                                    <div className="border-style"></div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-md-3 col-xl-3">
                            <div className="bg_2">
                                <Image src={require('../assets/images/rsvp/2.png')} alt="image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default RSVP;
