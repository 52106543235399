import React, { useState, useEffect } from "react";
import WOW from "wowjs";

const CountDown = () => {
    const [months, setMonths] = useState('00');
    const [days, setDays] = useState('00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');

    // Set the target date for the countdown
    const targetDate = new Date('2024-09-14T12:00:00').getTime();

    useEffect(() => {
        const countdown = () => {
            const now = new Date().getTime();
            const diff = targetDate - now;

            const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
            const days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setSeconds(seconds < 10 ? '0' + seconds : seconds);
            setMinutes(minutes < 10 ? '0' + minutes : minutes);
            setHours(hours < 10 ? '0' + hours : hours);
            setDays(days < 10 ? '0' + days : days);
            setMonths(months < 10 ? '0' + months : months);
        };

        const interval = setInterval(() => {
            countdown();
        }, 1000);

        return () => clearInterval(interval);

    }, [targetDate]);

    const [wow, setWow] = useState(new WOW.WOW());
    useEffect(() => {
        setWow(wow.init());
    }, []);

    return (
        <>            
            <div className="box wow zoomIn" data-wow-delay="0.3s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{months}</div> 
                    <span>Month</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.4s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{days}</div> 
                    <span>Days</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.5s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{hours}</div> 
                    <span>Hours</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.6s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{minutes}</div> 
                    <span>Mins</span> 
                </div>
            </div>
            <div className="box wow zoomIn" data-wow-delay="0.7s" data-wow-duration="0.8s">
                <div>
                    <div className="time">{seconds}</div> 
                    <span>Secs</span> 
                </div>
            </div>            
        </>
    );
}

export default CountDown;
