import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import "../assets/css/Address.css";
import Title from './Section-Title';


const Address = () =>{       
    const [show, setShow] = useState(false);
      
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return(
        <>                       
            <section className="address_section section-padding pt-0" id="address">
                <div className="container"> 
                    <Title sec_title={'Donde & Cuando'}/>
                    <div className="wpo-event-wrap">
                        <div className="row justify-content-center">
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>La Ceremonia</h2>
                                        <ul>
                                            <li>Sabado, 14 Sep, 2024
                                                11:00 AM – 12:00 PM</li>
                                            <li>Parroquia La Sagrada Familia,Los Castores, San Antonio de los Altos, Miranda</li>
                                            <li><a href="https://www.google.ca/maps/place/Parroquia+La+Sagrada+Familia+(Los+Castores)/@10.3727289,-66.9644355,17z/data=!3m1!4b1!4m6!3m5!1s0x8c2af37d2083169f:0x60dd51d9749a72bf!8m2!3d10.3727236!4d-66.9618606!16s%2Fg%2F1hdzp_730?entry=ttu" >Ver Ubicacion</a></li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="shape" className='img-fluid'/>
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="shape" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>Recepcion</h2>
                                        <ul>
                                            <li>Sabado, 14 Sep, 2024
                                                12:00 PM – 8:00 PM</li>
                                            <li>Villa l Encuentro, San Diego, Miranda</li>
                                            <li><a href="https://www.google.com/maps/place/Villa+el+Encuentro/@10.3467365,-66.9556008,19.21z/data=!4m6!3m5!1s0x8c2af365b6be3e5b:0x3b515fcebf111227!8m2!3d10.3471863!4d-66.9547834!16s%2Fg%2F11v42x12_5?entry=ttu" >Ver Ubicacion</a></li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="image" className='img-fluid'/>
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="image" className='img-fluid'/>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>Ven Y celebra!</h2>
                                        <ul>
                                            <li>Para nosotros, el mayor regalo es poder compartir este dia tan especial con ustedes. Pero si sienten que sus bolsillos estan demasiado pesados y quieren aligerarlos un poco, una contribucion en efectivo para nuestros planes futuros seria grandiosamente apreciada. Prometemos no gastarlo todo en Beto!</li>
                                      
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <Image src={require('../assets/images/bg/event-shape-1.png')} alt="image" />
                                    </div>
                                    <div className="event-shape-2">
                                        <Image src={require('../assets/images/bg/event-shape-2.png')} alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} className="locationModal">
              <Modal.Header closeButton className="border-0 pb-0">
                <Modal.Title className="text-danger ">Location</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <iframe width="100%" height="400px" title='location_iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25211.21212385712!2d144.95275648773628!3d-37.82748510398018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce7e0!2zTWVsYm91cm5lIFZJQyAzMDA0LCDgpoXgprjgp43gpp_gp43gprDgp4fgprLgpr_gpq_gprzgpr4!5e0!3m2!1sbn!2sbd!4v1503742051881" frameborder="0"></iframe>
              </Modal.Body>              
            </Modal>
        </>
    )
}
export default Address;